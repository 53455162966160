
/* eslint-disable */
import Vue from "vue";
import ChatService from "@/services/chat.service";
import HomeService from '@/services/home.service';
export default Vue.extend({
    name: 'validChat',
    data() {
        return {
            claroOscuro:"",
            originBot:"DefaultBot",
            modal:true,
            urlRedirigir:""
        }
    },
    mounted(){
        console.log("Validando Chat");
        this.cargaEstilos();
        console.log("Carga estilos");
        this.obtenerIP();
        console.log("obtiene ip");
    },
    methods: {
        obtenerIP: function(){         
            ChatService.obtenerIp().then(data => {
                console.log(data.data)
                const valores = window.location.search;
                const urlParams = new URLSearchParams(valores);
                if(urlParams.has('id')){
                    var id:any = atob(String(urlParams.get('id')));
                    var ClientBeginInfo = JSON.parse(id);
                    ClientBeginInfo.Ip=data.data;
                    this.cargaInicial(btoa(JSON.stringify(ClientBeginInfo)));
                }else{
                    this.$router.push("/Error");
                }
            }).catch(()=>{
                console.error("No se pudo obtener la IP");
                this.$router.push("/Error");
            });
        },
        cargaInicial: function (ClientBeginInfo:any) {
            ChatService.ValidChat(ClientBeginInfo).then( resp =>{
                let keyChat:string = resp.data.keyChat;
                localStorage.setItem("clientData-" + keyChat, resp.data.clientData);
                localStorage.setItem("CallCenterHoraInicio", resp.data.CallCenterHoraInicio);
                localStorage.setItem("CallCenterHoraFin", resp.data.CallCenterHoraFin);
                setTimeout(()=>{this.$router.push(resp.data.urlRedirigir);},100);
            }).catch(error=>{
                console.error("ocurrio un error",error);
                this.$router.push("/Error");
            });
        },
        cargaEstilos(){
            HomeService.CargaInicial().then((resp) => {
                localStorage.setItem("claroOscuro", resp.data.claroOscuro);
                localStorage.setItem("originBot", resp.data.originBot);
                this.originBot = resp.data.originBot;
                localStorage.setItem("pais", resp.data.pais);
                localStorage.setItem("urlRedirigir", resp.data.urlRedirigir);
                this.urlRedirigir = resp.data.urlRedirigir;
            }).catch((error) =>{
                console.error("Error al realizar la carga inicial validChat",error);
            });
        }
    }

})
